import IGeneric from "../interfaces/IGeneric";
import moment, { isDate } from "moment";

export function getFormattedString(props: IGeneric): string | undefined {

    const { title, attributes, validations, tableColumns, options, component, type } = props;
    if (!component || !attributes) return;

    const { value, currency, locale, decimals, unit, format } = attributes;
    const { pattern } = validations;
    if (value !== undefined) {
        let formattedString = value.toString();
        switch (type) {
            case "text":
            case "range":
            case "tel":
            case "date":
            case "email":
            case "number":
                if (pattern !== undefined && pattern !== "")
                    formattedString = formattedString.replace(new RegExp(pattern, "g"), "");
                else if (currency !== undefined)
                    formattedString = applyCurrencyFormat(formattedString, currency, locale, decimals);
                else if (unit !== undefined)
                    formattedString = applyNumberFormat(formattedString, unit, locale, decimals);
                else if (format !== undefined) {
                    formattedString = applyDateFormat(format, formattedString);
                }
                break;
            case "password":
                formattedString = "********";
                break;
            case "radio":
            case "button-radio":
            case "checkbox":
            case "select":
                if (Array.isArray(value)) {
                    const selectedValues = value as any[];
                    formattedString = options.filter((option: IGeneric) => selectedValues.includes(option.value)).map((option: IGeneric) => String(option.title)).join("\n");
                }
                else {
                    formattedString = options.filter((option: IGeneric) => value === option.value).map((option: IGeneric) => String(option.title)).join("\n");
                }
                break;
            case "file":
                if (Array.isArray(value))
                    formattedString = (value as IGeneric[]).map((file: IGeneric) => file.name).join("\n");
                else
                    formattedString = value.name;
                break;
            case "hidden":
                if (!title)
                    return;
                break;
            case "table":
                const tableData = value as IGeneric[];
                const table = `<table><thead><tr>
                    ${(tableColumns && tableColumns as IGeneric[]).map((column: IGeneric) =>
                    `<th>${column.title}</th>`).join("")}
                     </tr></thead><tbody>
                    ${tableData && tableData.map((row: IGeneric) =>
                        `<tr>${(tableColumns as IGeneric[]).map((column: IGeneric) =>
                            `<td>${row[`${column.dataIndex}`]}</td>`).join("")}
                         </tr>`).join("")}
                    </tbody></table>`;
                return table;
            case "tokenex-card":
                const { label, cardholderLabel, expiryLabel, cvvLabel } = attributes;
                const tokenExData = value as IGeneric;
                formattedString = "";
                if (tokenExData.cardholder) formattedString += ((cardholderLabel ?? "Cardholder") + ": " + tokenExData.cardholder + "\n");
                if (tokenExData.firstSix && tokenExData.lastFour) formattedString += ((label ?? "Card Number") + ": xxxx xxxx xxxx " + tokenExData.lastFour + "\n");
                if (tokenExData.expiry) formattedString += ((expiryLabel ?? "Expiry") + ": " + tokenExData.expiry + "\n");
                if (tokenExData.cvvIncluded) formattedString += ((cvvLabel ?? "CVV") + ": xxx\n");
        }

        if (formattedString === "")
            return;
        return formattedString.split("\n").join("<br/>");
    }

    return;
}
export function applyCurrencyFormat(value: string, currency: string, locale: string, decimals: number): string {
    const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
        currencyDisplay: "symbol",
        maximumFractionDigits: decimals ?? 0,
        minimumFractionDigits: decimals ?? 0,
    });

    return formatter.format(parseFloat(value));
}

export function applyNumberFormat(value: string, unit: string, locale: string, decimals: number): string {
    const formatter = new Intl.NumberFormat(locale, {
        maximumFractionDigits: decimals ?? 0,
        minimumFractionDigits: decimals ?? 0,
        unit,
    }
        /* {
        // @ts-ignore
        style: "unit",
        // @ts-ignore
        unit,
    }*/
    );
    return formatter.format(parseFloat(value));
}

export function applyDateFormat(format: string, value: string): string {
    //if (isDate(value))// && isDateFormat(format))
    return moment(value, "YYYY-MM-DD").format(format);
    //return value;
}

export function isDateFormat(format: string): boolean {
    var allowedFormats = ["DD-MM-YYYY", "YYYY-MM-DD", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY", "dddd Do MMMM"];
    return allowedFormats.includes(format);
}

export const getPrefix = (inputValue: string, attributes: IGeneric): string => {
    if (attributes.currency !== undefined) {
        return applyCurrencyFormat(inputValue, attributes.currency, attributes.locale, attributes.decimals).substring(0, 1);
    }
    else {
        return "";
    }
}

export const getSuffix = (inputValue: string, attributes: IGeneric): string => {
    if (attributes.unit !== undefined) {
        if (attributes.unit === "percent") {
            return "%";
        } else if (parseFloat(inputValue) > 1) {
            return `${attributes.unit}s`;
        } else {
            return attributes.unit;
        }
    }
    else {
        return "";
    }
}

export const getCurrentOptions = (options: IGeneric[], inputValue: string[] | undefined | null): IGeneric[] => {
    const inputValues = inputValue ?? [];
    return options.map((option: IGeneric) => {
        if (inputValues.includes(option.value)) option.checked = true;
        else if (inputValue === undefined || inputValue === null) option.checked = option.default;
        else option.checked = false;
        return option;
    });
}
